<template>
  <div
    ref="videoWrap"
    class="position-relative h-100 w-100 rounded-20 bg-dark"
  >
    <div
      class="h-100 d-flex flex-column justify-content-center text-center text-white fw-bold heading-section-4"
      :class="isAssembled ? 'd-none' : 'd-flex'"
    >
      Видео обрабатывается
    </div>
    <img
      :class="showPlayBtn || !isAssembled || (photoSrc !== null && isPhoto) ? 'd-none' : null"
      class="position-absolute top-50 start-50 translate-middle"
      src="/public/logo-only.svg"
      alt="sobes logo"
    >
    <img
      :class="{'d-none' : !isPhoto}"
      class="square position-absolute top-50 start-50 rounded-20"
      :src="photoSrc"
      :style="{
        height: `${wrapWidth}px !important`,
        width: `${wrapWidth}px !important`,
        transform: `translate(-50%, -50%)`,
      }"
      style="object-fit: cover"
    >
    <video
      ref="videoPreview"
      class="h-100 w-100 bg-transparent position-absolute start-50 top-50"
      :class="!isAssembled || (photoSrc !== null && isPhoto) ? 'd-none' : null"
      style="object-fit: cover;"
      :style="{
        height: videoHeight === undefined ? null : `${videoHeight}px !important`,
        width: videoWidth === undefined ? null : `${videoWidth}px !important`,
        transform: 'translate(-50%, -50%)' + (isReflection ? ' scaleX(-1)' : '') + (videoScale === undefined ? '' : ` scale(${videoScale})`),
      }"
      :src="getSrc"
      :srcObject.prop="stream"
      :autoplay="autoplay"
      :muted="isMuted"
      playsinline="true"
      @loadedmetadata="loadedmetadata"
      @click="play"
      @play="playEvent"
      @pause="pauseEvent"
      @stop="stopEvent"
      @ended="emits('isFinish')"
    />
    <canvas
      ref="Canvas"
      class="d-none"
    />
    <button
      v-show="showPlayBtn && isAssembled && !isPlayed"
      class="position-absolute start-50 top-50 translate-middle btn text-white rounded-circle bg-dark bg-opacity-25 p-0 pe-none"
      style="height: 100px; width: 100px"
    >
      <svg class="h-100 w-100">
        <use xlink:href="/public/icons/main.svg#play-arrow" />
      </svg>
    </button>
    <audio
      autoplay
      :srcObject.prop="audioStream"
    />
  </div>
</template>

<script setup lang="ts">
import type {PropType} from "vue";

const videoResize = useVideoResize();

//-----PROPS-----\\
const props = defineProps({
  isNextPlay: { type: Boolean, default: false },
  isPlay: { type: Boolean, default: false },
  isAssembled: { type: Boolean, default: true },
  stream: { type: Object as PropType<MediaStream>, default: undefined },
  audioStream: { type: Object as PropType<MediaStream>, default: undefined },
  src: { type: String, default: undefined },
  autoplay: { type: Boolean, default: undefined },
  isReflection: { type: Boolean, default: false },
  wrapHeight: { type: Number, default: undefined },
  wrapWidth: { type: Number, default: undefined },
  showPlayBtn: { type: Boolean },
  isPhoto: { type: Boolean, default: false },
  photoSrcOld: { type: String, default: null },
  isSetPhoto: { type: Boolean, default: false },
  isBlob: { type: Boolean, default: false },
});

const emits = defineEmits(['loadedmetadata', 'videoSize', 'timeline', 'isFinish', 'play', 'stop', 'pause', 'getPhoto', 'getVideo']);

//-----VARIABLES-----\\
let timer: Timer;
const size = 300;

//-----STATE-----\\
const videoWrap = ref<HTMLDivElement|null>(null);
const videoPreview = ref<HTMLVideoElement|null>(null);
const isPlayed = ref<boolean>(false);
const videoHeight = ref<number>();
const videoWidth = ref<number>();
const videoScale = ref<number|undefined>(undefined);
const Canvas = ref();
const photoBlob = ref<Blob|null>(null);
const photoSrc = ref<string|null>(props.photoSrcOld??null);
const isMuted = ref<boolean>(true);

//-----COMPUTED-----\\
const getSrc = computed<string|undefined>(() => {
  if (props.src === undefined) {
    return undefined;
  }

  return props.src + (props.isBlob ? '' : '#t=0.001');
});
watch(() => props.wrapHeight, (newHeight) => {
  if (newHeight !== undefined) {
    resize();
  }
});
watch(() => props.stream, (newStream, oldStream) => {
  if (oldStream !== undefined && oldStream.getVideoTracks().length !== 0 || newStream === undefined || newStream.getVideoTracks().length === 0) {
    return;
  }
  resize();
});
watch(() => props.src, async (newSrc) => {
  if (newSrc !== undefined) {
    resize();
  }

  if (props.isBlob) {
    await nextTick();
    videoPreview.value.load();
  }
});
watch(() => props.isNextPlay, (val: boolean) => {
  if (videoPreview.value === null || !props.isAssembled) {
    return;
  }
  if (val) {
    videoPreview.value.currentTime = 0;
    videoPreview.value.play();
  } else {
    videoPreview.value.pause();
  }
});
watch(() => props.isPlay, (val: boolean|null) => {
  if (videoPreview.value === null || val === !videoPreview.value.paused) {
    return;
  }

  play();
});
watch(() => props.isSetPhoto, (newVal) => {
  if (newVal) {
    const settingsVideo = props.stream.getVideoTracks()[0].getSettings();
    const scale = size/videoWidth.value;
    const ratioOriginalToViewVideo = settingsVideo.height / videoHeight.value;
    const ctx = Canvas.value.getContext("2d");

    Canvas.value.width = size;
    Canvas.value.height = size;
    ctx.scale(scale, scale);
    ctx.translate(videoWidth.value, 0)
    ctx.scale(-1, 1);
    ctx.drawImage(videoPreview.value,
      (settingsVideo.width - videoWidth.value * ratioOriginalToViewVideo) / 2, (settingsVideo.height - videoWidth.value * ratioOriginalToViewVideo) / 2,
      videoWidth.value * ratioOriginalToViewVideo, videoWidth.value * ratioOriginalToViewVideo,
      0,0,videoWidth.value,videoWidth.value
    );
    getBlobPhoto();

  } else {
    photoBlob.value = null;
    photoSrc.value = null;
    emits('getPhoto', {photoSrc: photoSrc.value, photoBlob: photoBlob.value});
  }
});

//-----METHODS-----\\
async function getBlobPhoto() {
  const blob = await new Promise((resolve) => {
    Canvas.value!.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg', 1);
  });
  photoBlob.value = blob;
  photoSrc.value = URL.createObjectURL(blob);

  videoPreview.value.pause();
  videoPreview.value.src = '';

  emits('getPhoto', {photoSrc: photoSrc.value, photoBlob: photoBlob.value});
}
function startTimer() {
  timeline();
  timer = setInterval(() => {
    timeline();
  }, 50);
}
function stopTimer() {
  clearInterval(timer);
  timeline();
}
async function play() {
  if (props.stream !== undefined && !props.isPhoto) {
    return;
  }

  if (videoPreview.value === null) {
    return;
  }

  if (!videoPreview.value.paused && !props.isPhoto) {
    videoPreview.value.pause();
    return;
  }

  if (videoPreview.value.ended) {
    videoPreview.value.currentTime = 0;
  }

  videoPreview.value.play();
}
function timeline() {
  if (videoPreview.value === null) {
    emits('timeline', 0, 0);
    return;
  }

  emits('timeline', videoPreview.value.currentTime * 100 / videoPreview.value.duration, videoPreview.value.currentTime);
}
function playEvent() {
  startTimer();
  emits('play');
  isPlayed.value = true;
  if (props.src !== undefined && !props.isPhoto) {
    isMuted.value = false;
  }
}
function pauseEvent() {
  stopTimer();
  emits('pause');
  isPlayed.value = false;
  isMuted.value = true;
}
function stopEvent() {
  stopTimer();
  emits('stop');
  isPlayed.value = false;
  isMuted.value = true;
}
function loadedmetadata(data) {
  emits('loadedmetadata', data);
  videoSize(data);
  resize()
}
function videoSize(data): { width: number, height: number } | null {
  if (data.target.videoWidth === undefined) {
    return null;
  }

  return {
    width: data.target.videoWidth,
    height: data.target.videoHeight,
  }
}
function resize() {
  if (props.wrapWidth === undefined || (props.src === undefined && props.stream === undefined)) {
    return;
  }

  if (videoPreview.value === null) {
    return;
  }

  if (props.src !== undefined && videoPreview.value!.videoWidth === undefined) {
    return;
  }

  const size = videoResize.get(
    {
      // width: videoWrap.value!.offsetWidth,
      width: props.wrapWidth,
      // height: videoWrap.value!.offsetHeight,
      height: props.wrapHeight,
    },
    {
      width: props.stream === undefined ? videoPreview.value!.videoWidth : props.stream.getVideoTracks()[0].getSettings().width!,
      height: props.stream === undefined ? videoPreview.value!.videoHeight : props.stream.getVideoTracks()[0].getSettings().height!,
    }
  );

  videoWidth.value = size.width;
  videoHeight.value = size.height;
  videoScale.value = size.scale;
}
onUnmounted(() => {
  stopTimer();
});
</script>
<style scoped lang="scss">
.square {
  z-index: 2;
  outline: 2px solid #fff;

  @media (min-width: 768px) {
    box-shadow: 0 0 400px 200px gray;
  }

  @media (max-width: 767px) {
    box-shadow: 0 0 150px 70px gray;
  }
}
</style>
